import h from 'h';
import React from 'react';
import { GetClubStorefrontPageQuery } from '__generated__/graphql';

import I18nContext from 'contexts/i18n_context';
import { Formik, Form } from 'formik';
import {
  FormActions,
  FormActionButton,
  FormField,
} from 'components/forms/forms';
import ProductBages from 'components/clubs/storefronts/product_badges';
import { ContentEditablePresenter } from 'components/utils/content_editable_presenter';

const i18nScope = 'components.clubs.storefronts.product_detail_view';

// TODO: this is too brittle. We shouldn't have to import these types
// it should be written in a way where it's flexible
type Club = NonNullable<GetClubStorefrontPageQuery['club']>;
type Storefront = NonNullable<Club['storefront']>;
type Product = NonNullable<Storefront['products']>[number];
type ProductVariant = NonNullable<Product['productVariants']>[number];

interface AddToCartFormValues {
  selectedProductVariantId: ProductVariant['id'] | null;
}

function AddToCart({
  product,
  addCartItemCallback,
  onCancelCallback,
}: {
  product: Product;
  addCartItemCallback: ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);

  const productVariants = product.productVariants ?? [];
  const initialValues = {
    selectedProductVariantId:
      productVariants.length > 0 ? String(productVariants[0].id) : null,
  };

  const _onSubmit = function (values: AddToCartFormValues) {
    const targetProductVariantId = values.selectedProductVariantId;

    let targetProductVariant: ProductVariant | null | undefined;
    if (productVariants.length === 0) {
      targetProductVariant = null;
    } else {
      targetProductVariant = productVariants.find(
        (pv) => String(pv.id) === targetProductVariantId,
      );
      if (typeof targetProductVariant === 'undefined') {
        h.throwError('selected product variant cannot be null');
        return;
      }
    }
    addCartItemCallback({ product, variant: targetProductVariant });
    // close the modal
    onCancelCallback();
  };

  return (
    <div className="">
      <Formik initialValues={initialValues} onSubmit={_onSubmit}>
        {() => (
          <Form>
            {productVariants.length > 0 && (
              <FormField as="select" name="selectedProductVariantId">
                {productVariants.map((productVariant, index) => (
                  <option key={index} value={productVariant.id}>
                    {productVariant.variant}
                  </option>
                ))}
              </FormField>
            )}
            <FormActions className="ltr">
              <FormActionButton
                text={i18n.t('add_to_cart', { scope: i18nScope })}
                isDisabled={product.isSoldOut}
                className="primary"
              />
              <FormActionButton
                text={i18n.t('close', { scope: i18nScope })}
                className="secondary"
                handleClick={onCancelCallback}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export function ProductDetailView({
  product,
  addCartItemCallback,
  onCancelCallback,
}: {
  product: Product;
  addCartItemCallback: ({
    product,
    variant,
  }: {
    product: Product;
    variant: ProductVariant | null;
  }) => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = React.useContext(I18nContext);
  return (
    <div className="storefront-product-detail-view">
      <div className="two-column-layout">
        <div className="column">
          <div className="product-img">
            <img src={product.imageUrls?.[0]} />
          </div>
        </div>
        <div className="column">
          <div className="product-info-header">
            <ProductBages product={product} />
            <h1>{product.name}</h1>
            <div className="price">
              {i18n.c({ amountInCents: product.priceInCents })}
            </div>
          </div>
          {!product.isSoldOut && (
            <div className="product-info-actions">
              <AddToCart
                product={product}
                addCartItemCallback={addCartItemCallback}
                onCancelCallback={onCancelCallback}
              />
            </div>
          )}
          <div className="product-info-body">
            <ContentEditablePresenter content={product.description} />
          </div>
        </div>
      </div>
    </div>
  );
}
