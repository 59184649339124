import { assertNotNullOrUndefined } from 'h';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';

import I18nContext from 'contexts/i18n_context';
import { ErrorPage, LoadingPage } from 'components/utils/pages_sidebar';
import { ClubId } from 'types/types';
import AdminDashboard from 'components/utils/admin/admin_dashboard';
import ProductForm, {
  buildNewProduct,
} from 'components/clubs/admin/storefront_page/product_form';

const i18nScope = 'clubs.admin.storefront';

const GET_CLUB_STOREFRONT_NEW_PRODUCT = gql(`
  query GetClubStorefrontNewProduct($clubId: ID!) {
    club(id: $clubId) {
      id
      currency
      storefront {
        id
      }
    }
  }
`);

const GET_CLUB_STOREFRONT_EDIT_PRODUCT = gql(`
  query GetClubStorefrontEditProduct($clubId: ID!, $productId: ID!) {
    club(id: $clubId) {
      id
      currency
      storefront {
        id
        product(id: $productId) {
          id
          name
          description
          priceInCents
          isUnlimitedStock
          isPubliclyVisible
          isMembersOnly
        }
      }
    }
  }
`);

export function StorefrontProductNewPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const newProduct = useMemo(() => buildNewProduct(), []);

  const { loading, error, data } = useQuery(GET_CLUB_STOREFRONT_NEW_PRODUCT, {
    variables: {
      clubId: String(currentClubId),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const storefront = club?.storefront;
  assertNotNullOrUndefined(storefront);

  return (
    <div id="clubs-admin-storefront-page">
      <AdminDashboard
        title={i18n.t('new.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <ProductForm
          club={club}
          storefront={storefront}
          isEditing={false}
          product={newProduct}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}

export function StorefrontProductEditPage({
  currentClubId,
}: {
  currentClubId: ClubId;
}) {
  const { i18n } = React.useContext(I18nContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const backToIndexPage = useMemo(() => () => navigate('..'), [navigate]);

  const { loading, error, data } = useQuery(GET_CLUB_STOREFRONT_EDIT_PRODUCT, {
    variables: {
      clubId: String(currentClubId),
      productId: String(id),
    },
  });
  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage />;
  const club = data?.club;
  assertNotNullOrUndefined(club);
  const storefront = club?.storefront;
  assertNotNullOrUndefined(storefront);
  const product = storefront.product;
  assertNotNullOrUndefined(product);

  return (
    <div id="clubs-admin-storefront-page">
      <AdminDashboard
        title={i18n.t('edit.title', { scope: i18nScope })}
        contentClasses="elevate-content min-height-page form-page"
      >
        <ProductForm
          club={club}
          storefront={storefront}
          isEditing={true}
          product={product}
          onSuccessCallback={backToIndexPage}
          onCancelCallback={backToIndexPage}
        />
      </AdminDashboard>
    </div>
  );
}
