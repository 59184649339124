import { assertIsNumber, assertNotNullOrUndefined } from 'h';
import _ from 'underscore';
import React, { useContext, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  Club,
  ClubStorefront,
  ClubProduct,
  AdminClubStorefrontProductCreateOrUpdateMutation,
} from '__generated__/graphql';
import { sendMutationAndUpdateForm } from 'helpers/gql_form_helpers';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import {
  FormActions,
  FormActionButton,
  FormField,
  FormSwitchField,
  FormServerErrorMessages,
  FormCurrencyField,
} from 'components/forms/forms';

import I18nContext from 'contexts/i18n_context';
import FormContentEditableField from 'components/forms/form_content_editable_field';

const i18nScope = 'clubs.admin.storefront.product_form';

export type ProductForForm = Pick<
  ClubProduct,
  | 'name'
  | 'description'
  | 'isUnlimitedStock'
  | 'isPubliclyVisible'
  | 'isMembersOnly'
> & {
  // TODO: issue#200 we need a better way of handling placeholder values
  // priceInCents is a number but I want to make it a string too
  // because when creating a new SubscriptionPlan I want to initialize
  // it with '' and not 0 so that it is blank for the user to fill out
  priceInCents: number | string;
};

export type ProductFormValues = Pick<
  ClubProduct,
  | 'name'
  | 'description'
  | 'isUnlimitedStock'
  | 'isPubliclyVisible'
  | 'isMembersOnly'
> & {
  priceInDollars: number | string;
};

const ADMIN_CLUB_STOREFRONT_PRODUCT_CREATE_OR_UPDATE = gql(`
  mutation AdminClubStorefrontProductCreateOrUpdate($input: ClubStorefrontProductCreateOrUpdateInput!) {
    clubStorefrontProductCreateOrUpdate(input: $input) {
      product {
        id
        name
        description
        priceInCents
        isPubliclyVisible
        isMembersOnly
      }
      errors {
        attribute
        messages
      }
    }
  }
`);

export function buildNewProduct(): ProductForForm {
  return {
    name: '',
    description: '',
    priceInCents: '',
    isUnlimitedStock: true,
    isPubliclyVisible: true,
    isMembersOnly: false,
  };
}

export default function ProductForm({
  club,
  storefront,
  isEditing,
  product,
  onSuccessCallback,
  onCancelCallback,
}: {
  club: Pick<Club, 'id' | 'currency'>;
  storefront: Pick<ClubStorefront, 'id'>;
  isEditing: boolean;
  // id is optional because it is only provided when editing, not adding
  product: ProductForForm & { id?: ClubProduct['id'] };
  onSuccessCallback: () => void;
  onCancelCallback: () => void;
}) {
  const { i18n } = useContext(I18nContext);
  const [createOrUpdateMutation] = useMutation(
    ADMIN_CLUB_STOREFRONT_PRODUCT_CREATE_OR_UPDATE,
  );

  const currency = club.currency;
  assertNotNullOrUndefined(currency);

  const initialValues: ProductFormValues = useMemo(
    () => ({
      ..._.pick(
        product,
        'name',
        'description',
        'isUnlimitedStock',
        'isPubliclyVisible',
        'isMembersOnly',
      ),
      priceInDollars: _.isNumber(product.priceInCents)
        ? product.priceInCents / 100
        : product.priceInCents, // handle if it's an empty string
    }),
    [product],
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(i18n.t('forms.errors.required'))
      .min(5, i18n.t('forms.errors.min_length')),
    description: Yup.string()
      .min(20, i18n.t('forms.errors.min_length'))
      .required(i18n.t('forms.errors.required')),
    priceInDollars: Yup.number()
      .moreThan(1, ({ more }) =>
        i18n.t('forms.errors.more_than', { values: { number: more } }),
      )
      .required(i18n.t('forms.errors.required')),
  });

  const _onSubmit = function (
    values: ProductFormValues,
    actions: FormikHelpers<ProductFormValues>,
  ) {
    const { priceInDollars } = values;
    assertIsNumber(priceInDollars);

    sendMutationAndUpdateForm<
      ProductFormValues,
      AdminClubStorefrontProductCreateOrUpdateMutation
    >({
      actions,
      mutationName: 'clubStorefrontProductCreateOrUpdate',
      main: () =>
        createOrUpdateMutation({
          variables: {
            input: {
              storefrontId: storefront.id,
              id: product?.id,
              ..._.omit(values, 'priceInDollars'),
              priceInCents: priceInDollars * 100,
            },
          },
        }),
      successCallback: () => {
        onSuccessCallback();
      },
    });
  };

  return (
    <div className="product-form">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={_onSubmit}
      >
        {({ values, status, isSubmitting }) => (
          <Form className="basic-form" autoComplete="off" noValidate>
            <FormField
              autoFocus={true}
              type="text"
              name="name"
              label={i18n.t('name.label', { scope: i18nScope })}
            />
            <FormCurrencyField
              name="priceInDollars"
              prefix={currency}
              label={i18n.t('price_in_dollars.label', { scope: i18nScope })}
            />
            <FormContentEditableField
              classes="as-input-field"
              name="description"
              value={values.description}
              shouldShowMenuBar={false}
              placeholder={i18n.t('description.placeholder', {
                scope: i18nScope,
              })}
              remoteImageUploadConfig={null}
            />

            <FormSwitchField
              name="isPubliclyVisible"
              label={i18n.t('is_publicly_visible.label', { scope: i18nScope })}
              description={i18n.t('is_publicly_visible.description', {
                scope: i18nScope,
              })}
            />
            <FormSwitchField
              name="isMembersOnly"
              label={i18n.t('is_members_only.label', { scope: i18nScope })}
              description={i18n.t('is_members_only.description', {
                scope: i18nScope,
              })}
            />
            <FormSwitchField
              name="isUnlimitedStock"
              label={i18n.t('is_unlimited_stock.label', { scope: i18nScope })}
              description={i18n.t('is_unlimited_stock.description', {
                scope: i18nScope,
              })}
              disabled={true}
            />
            <FormActions className="rtl">
              <FormActionButton
                text={i18n.t(
                  isEditing ? 'actions.submit.edit' : 'actions.submit.add',
                  { scope: i18nScope },
                )}
                className="primary"
                isSubmitting={isSubmitting}
              />
              <FormActionButton
                className="secondary"
                text={i18n.t('actions.cancel', { scope: i18nScope })}
                isDisabled={isSubmitting}
                handleClick={onCancelCallback}
              />
              <FormServerErrorMessages
                serverErrors={status?.serverErrors?.base}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
