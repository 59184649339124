// we should keep these in sync with app/helpers/dark_mode_helper.rb
export enum ThemeMode {
  SYSTEM_MODE = 'system-mode',
  DARK_MODE = 'dark-mode',
  LIGHT_MODE = 'light-mode',
}

export function setThemeMode(themeMode: ThemeMode): void {
  const body = document.body;
  body.classList.remove(ThemeMode.SYSTEM_MODE);
  body.classList.remove(ThemeMode.DARK_MODE);
  body.classList.remove(ThemeMode.LIGHT_MODE);
  body.classList.add(themeMode);
}

export function isCurrentlyInDarkMode(): boolean {
  const body = document.body;
  if (body.classList.contains(ThemeMode.SYSTEM_MODE)) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  } else if (body.classList.contains(ThemeMode.DARK_MODE)) {
    return true;
  }

  return false;
}
